import React, { useState } from "react";
import NodeNewsTeaser from "components/News/NodeNewsTeaser";
import Link from "next/link";
import Button from "components/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import SlidePrevButton from "components/Swiper/SlidePrevButton";
import SlideNextButton from "components/Swiper/SlideNextButton";

export function NodeNewsList({ nodes }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 gap-y-12">
      {nodes.map((node) => (
        <div key={node.id}>
          <NodeNewsTeaser node={node} fill />
        </div>
      ))}
    </div>
  );
}

export function NodeNewsListPreview({ nodes }) {
  const [slideNb, setSlideNb] = useState(0);
  const [swiper, setSwiper] = useState(null);

  return (
    <div className="container mx-auto px-8 pt-8 pb-16 lg:px-4 lg:py-10">
      <h3 className="mb-2 lg:mb-8 uppercase font-sans text-center text-lg xl:text-2xl tracking-widest">
        blog
      </h3>
      <div className="lg:hidden">
        <h2 className="mb-8 lg:mb-16 text-center block text-5xl font-teko uppercase">
          <span>Nos dernières</span>
          <span className="text-primary"> actualités</span>
        </h2>
        <div className="flex flex-col space-y-8">
          {nodes.map(
            (node, index) =>
              index < 3 && (
                <div key={node.id}>
                  <NodeNewsTeaser node={node} wide />
                </div>
              )
          )}
        </div>
      </div>
      <div className="hidden lg:block">
        <div className="relative">
          <h2 className="mb-16 text-center block text-5xl xl:text-7xl 2xl:text-8xl font-teko uppercase">
            <span>Nos dernières</span>
            <span className="text-primary"> actualités</span>
          </h2>
          {swiper && nodes.length > 3 && (
            <div className="absolute top-2 right-0 flex gap-2.5 2xl:gap-8 items-center justify-center">
              <SlidePrevButton
                theme="outline-dark"
                disabled={slideNb === 0}
                swiper={swiper}
              />
              <SlideNextButton
                theme="outline-dark"
                disabled={slideNb === nodes.length - 3}
                swiper={swiper}
              />
            </div>
          )}
        </div>
        <Swiper
          slidesPerView={3}
          spaceBetween={40}
          modules={[Navigation]}
          onInit={setSwiper}
          onSlideChange={(index) => setSlideNb(index.snapIndex)}
        >
          {nodes.map((node) => (
            <SwiperSlide key={node.id}>
              <NodeNewsTeaser node={node} wide />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="text-center mt-10 lg:mt-16">
        <Link href="/actualites" passHref>
          <Button as="a" theme="primary" inverse>
            Plus d&apos;actualités
          </Button>
        </Link>
      </div>
    </div>
  );
}
