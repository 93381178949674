import React from "react";
import { getResourceCollectionFromContext } from "next-drupal";
import Layout from "components/Layout";
import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import { getGlobals } from "lib/getGlobals";
import { NodeNewsListPreview } from "components/News/NodeNewsList";
import Meta from "components/Meta";
import {
  NodeVehicleListPreviewForSale,
  NodeVehicleListPreviewSold,
} from "components/Vehicle/NodeVehicleList";
import ImageLinkBlock from "components/Block/ImageLinkBlock";

export default function IndexPage({ nodes, globals }) {
  return (
    <Layout globals={globals} banners={nodes.banners} home>
      <Meta title="Accueil" />

      {globals?.blocks?.expert && (
        <ImageLinkBlock data={globals.blocks.expert} />
      )}

      {nodes?.vehiclesForSale?.length > 0 && (
        <NodeVehicleListPreviewForSale nodes={nodes.vehiclesForSale} />
      )}

      {nodes?.vehiclesSold?.length > 0 && (
        <NodeVehicleListPreviewSold nodes={nodes.vehiclesSold} />
      )}

      {nodes?.newses?.length > 0 && (
        <NodeNewsListPreview nodes={nodes.newses} />
      )}
    </Layout>
  );
}

export async function getStaticProps(context) {
  const bannersParams = new DrupalJsonApiParams();
  const newsesParams = new DrupalJsonApiParams();
  const vehiclesSoldParams = new DrupalJsonApiParams();
  const vehiclesForSaleParams = new DrupalJsonApiParams();

  bannersParams
    .addInclude(["field_image"])
    .addFilter("status", 1)
    .addFilter("promote", 1)
    .addSort("sticky", "DESC")
    .addSort("created", "DESC")
    .addPageLimit(5);

  // Recupérer les vehicules en ventes
  vehiclesForSaleParams
    .addInclude(["field_fuel", "field_slider"])
    .addFilter("status", 1)
    .addFilter("field_availablity", "1")
    .addSort("created", "DESC")
    .addPageLimit(8);

  const vehiclesForSale = await getResourceCollectionFromContext(
    "node--vehicle",
    context,
    {
      params: vehiclesForSaleParams.getQueryObject(),
    }
  );

  // Recupérer les vehicules vendus
  vehiclesSoldParams
    .addInclude(["field_fuel", "field_slider"])
    .addFilter("status", 1)
    .addFilter("field_availablity", "2")
    .addSort("created", "DESC")
    .addPageLimit(8);

  const vehiclesSold = await getResourceCollectionFromContext(
    "node--vehicle",
    context,
    {
      params: vehiclesSoldParams.getQueryObject(),
    }
  );

  newsesParams
    .addInclude(["field_image"])
    .addFilter("status", 1)
    .addFilter("promote", 1)
    .addSort("sticky", "DESC")
    .addSort("created", "DESC")
    .addPageLimit(8);

  const banners = await getResourceCollectionFromContext(
    "node--banner",
    context,
    {
      params: bannersParams.getQueryObject(),
    }
  );

  const newses = await getResourceCollectionFromContext("node--news", context, {
    params: newsesParams.getQueryObject(),
  });

  return {
    props: {
      globals: await getGlobals(context),
      nodes: {
        banners,
        newses,
        vehiclesSold,
        vehiclesForSale,
      },
    },
    revalidate: 30,
  };
}
