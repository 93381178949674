import React from "react";
import Image from "next/image";
import Link from "next/link";
import { PlusIcon } from "@heroicons/react/outline";
import { useRouter } from "next/router";
import classNames from "lib/classNames";
import usePlaceholder from "lib/usePlaceholder";

export default function NodeNewsTeaser({ node, wide = false, fill = false }) {
  const router = useRouter();
  const placeholder = usePlaceholder();

  const clickHandler = () => {
    router.push(node.path.alias);
  };

  return (
    <article
      className={classNames(
        fill && "h-full",
        "group flex flex-col cursor-pointer pb-6"
      )}
      onClick={() => clickHandler()}
    >
      <div
        className={classNames(
          fill && "flex-1",
          "relative pb-10 2xl:pb-24 border-b-2 border-black"
        )}
      >
        <div className="flex flex-col">
          <div
            className="bg-gray-400 overflow-hidden aspect-w-1 aspect-h-1"
            aria-hidden="true"
          >
            <div>
              {node.field_image?.image_style_uri.medium ? (
                <Image
                  src={node.field_image.image_style_uri.medium}
                  width={node.field_image.resourceIdObjMeta.width}
                  height={node.field_image.resourceIdObjMeta.height}
                  layout="fill"
                  objectFit="cover"
                  alt={node.field_image.resourceIdObjMeta.alt}
                  className="group-hover:scale-110 transition"
                />
              ) : (
                <Image
                  {...placeholder}
                  layout="fill"
                  objectFit="cover"
                  className="group-hover:scale-110 transition"
                />
              )}
            </div>
          </div>
          <h2 className="text-3xl xl:text-4xl 2xl:text-5xl underline-offset-4 transition pt-8">
            {node.title}
          </h2>

          {wide && node.body.summary && (
            <p className="text-sm 2xl:text-lg mt-6 text-black text-opacity-60">
              {node.body.summary}
            </p>
          )}

          <Link href={node.path.alias} passHref>
            <a className="absolute -bottom-6 right-10 bg-black group-hover:bg-primary text-white p-4 transition -skew-x-12">
              <PlusIcon className="h-5 w-5 stroke-[4px] skew-x-12" />
              <span className="sr-only">Voir tout</span>
            </a>
          </Link>
        </div>
      </div>
    </article>
  );
}
