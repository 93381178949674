import Image from "next/image";
import React from "react";
import Body from "components/Body";
import Link from "next/link";

export default function ImageLinkBlock({ data }) {
  const baseTitle = data.field_title.split(" ");
  const lastWordTitle = baseTitle.pop();
  const startTitle = baseTitle.join().replace(",", " ");

  return (
    <div className="bg-image-link-block bg-center bg-no-repeat">
      <div className="container mx-auto px-4 text-center lg:text-left py-16 lg:py-32">
        <h2 className="font-sans uppercase tracking-widest pb-4 text-xl 2xl:text-2xl">
          {data.field_subtitle}
        </h2>
        <div className="flex flex-col lg:flex-row lg:space-x-16 lg:pb-16">
          <div className="flex flex-col">
            <h1 className="xl:leading-[0.85] text-5xl xl:text-7xl 2xl:text-8xl uppercase">
              <span className="text-primary">{startTitle}</span>
              <span> {lastWordTitle}</span>
            </h1>
            <div className="lg:hidden aspect-h-1 aspect-w-2 mt-8">
              <Image
                src={data.field_image.image_style_uri.hq}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                alt={data.field_image.resourceIdObjMeta.alt}
              />
            </div>
            <p className="mt-6 lg:mt-10 pb-6 lg:pb-0">{data.body?.summary}</p>
          </div>
          <div className="lg:max-w-4xl">
            <Body value={data.body?.value} />
          </div>
        </div>
        <div className="hidden lg:block pt-16 relative">
          <div className="aspect-h-1 aspect-w-3">
            <Link href={data.field_link.url}>
              <a>
                <Image
                  src={data.field_image.image_style_uri.hq}
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                  alt={data.field_image.resourceIdObjMeta.alt}
                />
              </a>
            </Link>
          </div>
          <Link href={data.field_link.url} passHref>
            <a className="absolute top-12 right-10 bg-primary uppercase text-white px-4 pt-2.5 pb-1.5 2xl:px-8 2xl:pt-5 2xl:pb-3.5 font-teko text-xl 2xl:text-3xl transition shadow-2xl drop-shadow-2xl">
              <span>En savoir plus</span>
            </a>
          </Link>
        </div>
        <div className="lg:hidden mt-16">
          <Link href={data.field_link.url} passHref>
            <a className="bg-primary uppercase text-white px-8 py-2 font-teko text-xl">
              <span>En savoir plus</span>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}
